import { graphql } from "gatsby"
import React from "react"
import DatoContentModules from "../dato/DatoContentModules"
import BackgroundMedia from "../molecules/BackgroundMedia"
import PageHero from "../molecules/PageHero"
//import * as styles from "./ProductLandingPage.module.scss"

const ProductLandingPage = (props: Queries.DatoProductLandingPageFragment) => {
  //console.log('Rendering ProductLandingPage', props)

  /*  const relatedProductsQuery = JSON.parse(props.relatedProducts)
    .map(itm => "sku:" + itm)
    .join()
  const relatedProductFamiliesQuery = JSON.parse(props.relatedProductFamilies)
    .map(itm => "product_family:" + itm)
    .join() */

  return (
    <>
      <PageHero media={<BackgroundMedia {...props.mainMedia} />} content={<h1>{props.title}</h1>} />

      <DatoContentModules modules={props.modules} />
      {/* 
      <div className="layout-container">
        <ProductFilter query={relatedProductFamiliesQuery || relatedProductsQuery} />
      </div> */}
    </>
  )
}

export default ProductLandingPage

export const query = graphql`
  fragment DatoProductLandingPage on DatoCmsProductLandingPage {
    title
    relatedProducts
    relatedProductFamilies
    mainMedia {
      ...DatoMedia
    }
    ...DatoProductLandingPageModules
  }
`
